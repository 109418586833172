<template>
  <div class="w-100">
    <table class="table table-hovered table-striped table-bordered">
      <thead>
        <tr class="text-center">
          <th>
            <img src="../../../assets/images/played.png" alt="Partidos jugados" class="img-icon-sm">
          </th>
          <th>
            <img src="../../../assets/images/goals.png" alt="Goles" class="img-icon-sm">
          </th>
          <th>
            <img src="../../../assets/images/assist.png" alt="Asistencias" class="img-icon-sm">
          </th>
          <th>
            <img src="../../../assets/images/yellow.png" alt="Amarillas" class="img-icon-sm">
          </th>
          <th>
            <img src="../../../assets/images/red.png" alt="Rojas" class="img-icon-sm">
          </th>
          <th>
            <img src="../../../assets/images/best.png" alt="Mejor jugador" class="img-icon-sm">
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center">
          <td>{{ playerFront.pjs }}</td>
          <td>{{ playerFront.goals }}</td>
          <td>{{ playerFront.assists }}</td>
          <td>{{ playerFront.yellow_cards }}</td>
          <td>{{ playerFront.red_cards }}</td>
          <td>{{ playerFront.bests }}</td>
        </tr>
      </tbody>
    </table>

    <div class="row justify-content-center py-3">
      <div class="col-12 col-sm-6 col-md-12">
        <chart :chart-data="playerFront.chart" height="100"></chart>
      </div>
    </div>

    <table v-if="playerFront.especial" class="table table-hovered table-striped table-bordered">
      <thead>
        <tr class="text-center">
          <th>
            <img src="../../../assets/images/played.png" alt="Partidos jugados" class="img-icon-sm">
          </th>
          <th>
            <img src="../../../assets/images/goals.png" alt="Goles" class="img-icon-sm">
          </th>
          <th>
            <span>{{ $t('gc') }}</span>
          </th>
          <th>
            <span>{{ $t('pg') }}</span>
          </th>
          <th>
            <span>{{ $t('pe') }}</span>
          </th>
          <th>
            <span>{{ $t('pp') }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center">
          <td>{{ playerFront.especial.fixture.pj }}</td>
          <td>{{ playerFront.especial.goals }}</td>
          <td>{{ playerFront.especial.fixture.gc }}</td>
          <td>{{ playerFront.especial.fixture.pg }}</td>
          <td>{{ playerFront.especial.fixture.pe }}</td>
          <td>{{ playerFront.especial.fixture.pp }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Chart from '../partials/ChartBar.js'

export default {
  components: {
    Chart
  },
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'playerFront'
    ])
  },
  mounted () {
    this.$store.dispatch('SET_MENU_PRIMARY_USER', 'home')
  }
}
</script>
